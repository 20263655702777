import { useRouter } from "next/router"
import Link from "next/link"
import dynamic from "next/dynamic"

// ---------------------------------------------------------

import Layout from "@layout/index"

import Button from "@components/button"
import Grid from "@components/grid"

const PingPong = dynamic(() => import("@components/pingpong"), { ssr: false })

// ---------------------------------------------------------

const NotFoundPage = () => {
  const router = useRouter()

  const attemptedPath = router.asPath.split("/")[1]

  const validPaths = ["blog", "resources", "events", "solutions", "partners", "customers"]
  const isValidPath = validPaths.indexOf(attemptedPath) >= 0

  const default404Message = (
    <>
      <p>Perhaps you can find what you're looking for on one of these pages:</p>
      <p>
        <Link href="/">Home</Link>
        <br />
        <Link href="/resources">Resources</Link>
        <br />
        <Link href="/blog">Blog</Link>
        <br />
        <Link href="/announcements">Announcements</Link>
        <br />
        <a href="https://docs.amperity.com/" target="_blank">
          Docs
        </a>
      </p>
    </>
  )

  const dynamic404Message = (
    <>
      <p>Oops. We can't seem to find the page you're looking for.</p>
      <Button theme="dark" url={`/${attemptedPath}`}>
        Back to {attemptedPath}
      </Button>
    </>
  )

  return (
    <Layout
      pageTitle="404 Page Not Found"
      featuredImage={{
        url: "https://images.ctfassets.net/5vqt5w81x2im/1f22Sbw9c71UOCy7ndfLhy/79566cd01082b5780828dd8166c21647/Generic-404_PingPong.png",
        description: "404"
      }}
    >
      <PingPong
        image={{
          src: "https://images.ctfassets.net/5vqt5w81x2im/1f22Sbw9c71UOCy7ndfLhy/79566cd01082b5780828dd8166c21647/Generic-404_PingPong.png",
          alt: "404",
          width: 1080,
          height: 700
        }}
      >
        <h1>404: Page not found</h1>
        {isValidPath ? dynamic404Message : default404Message}
      </PingPong>
      <Grid backgroundColor="off-black" column={1}>
        <div>
          <span className="label">Get in touch</span>
          <h2>Put the power of Amperity to work today.</h2>
          <Button url="/contact-sales">Contact us</Button>
        </div>
      </Grid>
    </Layout>
  )
}

export default NotFoundPage
